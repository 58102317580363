import React from "react";
import JobVacancy from "./JobVacancy";
// import Popular from "../JobList/Popular";
import Section from "./Section";

const JobGrid = () => {
  document.title = "Offres d'emploi | RecruTech";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
          {/* <Popular /> */}
          <JobVacancy />
      </section>
    </React.Fragment>
  );
};

export default JobGrid;
