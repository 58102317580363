import api from "../Api"

const fetchCompanies =  async () => {
    return await api.get("api/v1/public/candidate/companies/all");
};

const fetchCompaniesPaged =  async (page, size=10) => {
    return await api.get(`api/v1/public/candidate/companies/paged?page=${page}&size=${size}`);
};

const CompanyService = {
    fetchCompanies,
    fetchCompaniesPaged,
};

export default CompanyService;