import React from "react";
import { Row, Col } from "reactstrap";

const BlogCategory = ({
  modules = [],
  onSelectCourse,
  selectedCourse,
  activeModule,
  setActiveModule
}) => {
  // Styles des liens
  const linkStyle = {
    textDecoration: 'none',
    display: 'block',
    padding: '10px',
    color: '#000000', // Couleur noire par défaut
    borderRadius: '4px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const activeLinkStyle = {
    ...linkStyle,
    color: '#ffffff', // Couleur blanche pour le cours sélectionné
    backgroundColor: '#14B8A6', // Couleur de fond verte pour le cours sélectionné
  };

  return (
    <div className="blog-category">
      {modules.map((module) => (
        <div className="module-section" key={module.id}>
          <div className="module-title mb-3">
            <h6 className="fs-14 mb-1">{module.title}</h6>
          </div>
          <Row className="mb-4">
            {module.courses && module.courses.map((course) => (
              <Col key={course.id} sm={12} className="mb-2">
                <a
                  href="#!"
                  onClick={() => onSelectCourse(course)} // Mettre à jour le cours sélectionné
                  style={selectedCourse && selectedCourse.id === course.id ? activeLinkStyle : linkStyle}
                >
                  {course.title}
                </a>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default BlogCategory;
