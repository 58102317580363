import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeneficiaryAcademies } from "../../../redux/training/action";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import Pagination from "../../CandidateAndCompany/CompanyList/Pagination"; // Importez votre composant Pagination

const JobVacancyList = () => {
  document.title = "Nos Formations | RecruTech";
  const firstCardRef = useRef(null);
  const dispatch = useDispatch();
  const { loading, allAcademies, error } = useSelector(
    (state) => state.trainingReducer
  );

  const setCurrentPage = (page) => {
    dispatch(fetchBeneficiaryAcademies(page)); // Passer la page actuelle
  };

  useEffect(() => {
    // Initialiser avec la première page
    dispatch(fetchBeneficiaryAcademies(allAcademies.currentPage)); // Passer 1 pour la première page
    window.scrollTo(0, 0);
  }, [dispatch]);

  // Paramètres pour l'animation Lottie de chargement
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
    // Paramètres pour l'animation Lottie de données vides
    const emptyOptions = {
      loop: true,
      autoplay: true,
      animationData: emptyAnimationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

  return (
    <React.Fragment>
      <section className="mt-4">
        <Container>
          {loading ? (
            <div className="text-center mt-5">
              <Lottie options={loadingOptions} height={300} width={300} />
            </div>
          ) : error ? (
            <div className="text-center mt-5">
              <Lottie options={errorOptions} height={300} width={300} />
              <p className="text-danger mt-3">
                Une erreur s'est produite : {error}
              </p>
            </div>
          ): allAcademies.content.length === 0 ? (
            <div className="text-center mt-5">
              <Lottie options={emptyOptions} height={300} width={300} />
              <p className="text-muted mt-3">
                Aucune académie trouvée. Veuillez réessayer plus tard.
              </p>
            </div>
          ) : (
            <Row>
              {allAcademies.content &&
                allAcademies.content.map((academy) => (
                  <Col md={6} key={academy.id} ref={firstCardRef}>
                    <div className="job-box bookmark-post card mt-4">
                      <div className="bookmark-label text-center">
                        <Link to="#" className="align-middle text-white">
                          <i className="mdi mdi-star"></i>
                        </Link>
                      </div>
                      <div className="p-4">
                        <Row className="align-items-center mb-1">
                          <Col md={8}>
                            <div className="text-start">
                              <h5 className="fs-18 mb-1">
                                <Link
                                  to={`/academydetails/${academy.slug}`} // Changez cela en fonction de votre route
                                  className="primary-link"
                                >
                                  {academy.title}
                                </Link>
                              </h5>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="text-end mb-4">
                              <img
                                src={
                                  academy.urlPicture ||
                                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                                }
                                alt=""
                                className="img-fluid rounded-3"
                                width={50}
                                height={50}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="text-align-justify mb-0">
                          <Col md={7}>
                            <div style={{ textAlign: "justify" }} className="text-start mb-0">
                              <p className="text-muted fs-14 mb-0">{academy.edition}</p>
                            </div>
                          </Col>
                          <Col md={5}>
                            {/* <div className="text-end mb-0 mb-md-0">
                              <p className="text-muted fs-14 mb-0">{academy.country}</p>
                            </div> */}
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 bg-light">
                        <Row className="justify-content-between">
                          <Col md={8}>
                            <div className="d-flex mb-0">
                              <div className="flex-shrink-0">
                                <i className="mdi mdi-map-marker text-primary me-1"></i>
                              </div>
                              <p className="text-muted mb-0">{academy.country}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          )}
          <Pagination
                currentPage={allAcademies.currentPage}
                totalPages={allAcademies.totalPages}
                fetchData={fetchBeneficiaryAcademies}
                setCurrentPage={setCurrentPage}
                firstCardRef={firstCardRef}
              />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobVacancyList;
