import React from "react";
import { Form } from "react-bootstrap";
import { Col, Row, Input, Button } from "reactstrap";
import { Collapse } from "reactstrap";

const FormSection = ({ isOpen, toggleSection, sectionId, sectionTitle }) => {
  return (
    <>
      <button
        className="text-start w-100 btn btn-outline-primary"
        onClick={() => toggleSection(sectionId)}
        aria-expanded={isOpen}
      >
        {sectionTitle}
      </button>
      <hr />
      <Collapse isOpen={isOpen}>
        <Form action="#">
          <div className="mt-3">
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Saisissez votre nom"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Input
                    type="text"
                    name="prenom"
                    id="prenom"
                    className="form-control"
                    placeholder="Saisissez votre prénom"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Saisissez votre email"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    name="telephone"
                    id="telephone"
                    placeholder="Saisissez votre téléphone"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Saisissez votre message"
                    name="message"
                    id="message"
                    rows="3"
                  ></textarea>
                </div>
              </Col>
            </Row>
            <div className="text-start">
              <Button
                type="submit"
                id="submit"
                name="submit"
                className="btn btn-warning"
              >
                Envoyer
                <i className="uil uil-message ms-1"></i>
              </Button>
            </div>
          </div>
        </Form>
      </Collapse>
    </>
  );
};

export default FormSection;
