import React from 'react';

const VoluntaryDisclosure = ({ data, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div>
      <p>Veuillez lire et accepter les modalités ci-dessous:</p>
      <p>
        Texte des modalités de divulgation volontaire...
      </p>
      <label>
        <input
          type="checkbox"
          checked={data}
          onChange={handleChange}
          required
        />
        J'accepte les modalités
      </label>
    </div>
  );
};

export default VoluntaryDisclosure;
