import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MasonaryContent.css"; // Assurez-vous d'importer le fichier CSS

const Section = () => {
  return (
    <React.Fragment>
      <section className="custom-background-section-training">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="text-center custom-content">
                <h3 className="mb-4">Formation</h3>
                <div className="page-next">
                  <nav className="d-inline-block" aria-label="breadcrumb text-center">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Acceuil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Formation</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Liste des formations
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
