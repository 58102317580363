import React, { useState } from "react";
import { Collapse } from "reactstrap";

const AccordianContentRight = () => {
  //Collapse Tab

  const [isCollapseFourth, setIsCollapseFourth] = useState(true);
  const toggleFourth = () => setIsCollapseFourth(!isCollapseFourth);

  const [isCollapseFifth, setIsCollapseFifth] = useState(false);
  const toggleFifth = () => setIsCollapseFifth(!isCollapseFifth);

  const [isCollapseSixth, setIsCollapseSixth] = useState(false);
  const toggleSixth = () => setIsCollapseSixth(!isCollapseSixth);
  return (
    <React.Fragment>
      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="generalfour">
          <button
            className="accordion-button"
            onClick={toggleFourth}
            type="button"
          >
            Est-ce que Recrutech propose des conseils pour préparer un CV ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseFourth} id="general-four">
          <div className="accordion-body">
            Oui, Recrutech offre des ressources et des conseils pour vous aider à améliorer votre CV,
            à rédiger une lettre de motivation convaincante et à vous préparer aux entretiens d'embauche.
          </div>
        </Collapse>
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="generalfive">
          <button
            className="accordion-button"
            onClick={toggleFifth}
            type="button"
          >
            Puis-je recevoir des alertes pour les nouvelles offres d'emploi ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseFifth} id="general-five">
          <div className="accordion-body">
            "Oui, vous pouvez configurer des alertes pour recevoir des notifications par e-mail ou
            via l'application lorsque de nouvelles offres d'emploi correspondant à vos critères sont disponibles.
          </div>
        </Collapse>
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="generalsix">
          <button
            className="accordion-button"
            onClick={toggleSixth}
            type="button"
          >
            Comment puis-je créer un compte sur Recrutech ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseSixth} id="general-six">
          <div className="accordion-body">
            Pour créer un compte sur Recrutech, rendez-vous sur la page d'inscription et
            remplissez les informations requises telles que votre nom, votre adresse e-mail et créez un mot de passe sécurisé.
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default AccordianContentRight;
