import React from "react";
import { Col, Container, Row, Input, Button } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";
// import Selected from "./Selected";

const Section = () => {
  return (
    <React.Fragment>
      <section className="page-title-box">
        <Container>
          <Row className="justify-content-center mb-3">
            <Col md={8} className="col-md-6">
              <div className="text-center text-white">
                <h3 className="mb-4">Découvrez votre prochaine opportunité professionnelle.</h3>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={12} className="col-md-6">
              <div className="text-center text-white">
                <form action="#">
                  <Row className="g-2 mb-3">
                    <Col lg={5} md={6}>
                      <div className="filler-job-form">
                        <i className="uil uil-briefcase-alt"></i>
                        <Input
                          type="search"
                          className="form-control filter-input-box"
                          id="exampleFormControlInput1"
                          placeholder="Rechercher par emploi, mot clé ou entreprise "
                          style={{ marginTop: "-10px" }}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="filler-job-form">
                        <i className="uil uil-location-point"></i>
                        <CountryOptions />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="filler-job-form">
                        <i className="uil uil-clipboard-notes"></i>
                        <JobType />
                      </div>
                    </Col>
                    <Col lg={1} md={6}>
                      <Button className="dropdown-item " style={{ color: 'white', borderRadius: '50%', width: '60px', height: '50px' }}>
                        <i className="uil uil-search me-1 fs-3"></i>
                      </Button>
                    </Col>
                  </Row>
                  {/* <Row className="justify-content-center">
                    <Col md={8} className="col-md-6">
                      <Selected />
                    </Col>
                  </Row> */}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
