import {
    FETCH_JOB_CATEGORIES_REQUEST,
    FETCH_JOB_CATEGORIES_SUCCESS,
    FETCH_JOB_CATEGORIES_FAILURE,
    FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS,
    FETCH_JOB_BY_CATEGORY_FAILURE, FETCH_JOB_BY_CATEGORY_REQUEST, FETCH_JOB_BY_CATEGORY_SUCCESS
  } from '../types';
  
  const initialState = {

    jobCategories: [],
    jobByCategory: [],
    countries: [],
    loading: false,
    error: null,
  };
  
  const jobCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_JOB_CATEGORIES_REQUEST:
        return { ...state, loading: true };
      case FETCH_JOB_CATEGORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          jobCategories: action.payload.data,
          error: null,
        };
      case FETCH_JOB_CATEGORIES_FAILURE:
        return { ...state, loading: false, error: action.payload };

      // case FETCH_JOB_BY_CATEGORY

      case FETCH_JOB_BY_CATEGORY_REQUEST:
        return { ...state, loading: true };
      case FETCH_JOB_BY_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          jobByCategory: action.payload.data,
          error: null,
        };
      case FETCH_JOB_BY_CATEGORY_FAILURE:
        return { ...state, loading: false, error: action.payload };
      
      // case FETCH_COUNTRIES

      case FETCH_COUNTRIES_REQUEST:
        return { ...state, loading: true };
      case FETCH_COUNTRIES_SUCCESS:
        return {
          ...state,
          loading: false,
          countries: action.payload.data,
          error: null,
        };
      case FETCH_COUNTRIES_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default jobCategoryReducer;
  