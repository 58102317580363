import React, { useState, useEffect } from "react";
import Section from "../Contact/Section";
import ContactContent from "../Contact/ContactContent";
import Lottie from "react-lottie";
import * as animationData from "../../../src/assets/loading.json"; // Remplacez par le chemin de votre fichier Lottie

const Contact = () => {
  document.title = "Contact | RecruTech";

  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simuler un temps de chargement
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Temps de chargement simulé de 3 secondes

    return () => clearTimeout(timer); // Nettoyage du timer
  }, []);

  // Paramètres pour l'animation Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      {loading ? (
        <div className="text-center">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      ) : (
        <>
          <ContactContent />
        </>
      )}
    </React.Fragment>
  );
};

export default Contact;
