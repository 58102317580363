import companyService from '../../../service/company';
import { FETCH_COMPANIES_FAILURE, FETCH_COMPANIES_REQUEST, FETCH_COMPANIES_SUCCESS } from '../types';

export const fetchCompaniesPaged =  (page) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMPANIES_REQUEST });
    try {
      const response = await companyService.fetchCompaniesPaged(page);
      dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_COMPANIES_FAILURE, payload: error.message });
    }
  };
};

export const fetchCompanies = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMPANIES_REQUEST });
    try {
      const response = await companyService.fetchCompanies();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: FETCH_COMPANIES_FAILURE, payload: error.message });
    }
  };
};
