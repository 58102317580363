import TokenService from "../TokenService";

const AuthHeader = token => {
    const accessToken = token ? token : TokenService.getLocalAccessToken();
    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken };
    } else {
        return {};
    }
}

export default AuthHeader;
