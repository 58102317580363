import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { formatAddress } from "../../../utils";

const CompanyCard = ({ company }) => {
  return (
    <Card className="blog-masonry-box shadow overflow-hidden border-0 p-2">
      <div>
        <img
          src={
            company.coverPhoto ||
            "https://res.cloudinary.com/dryskp7hv/image/upload/v1701773538/Eburnie%20hub/img/modern-business-buildings_720_aka0ak.jpg"
          } // Remplacez par l'URL appropriée depuis l'objet companyImages
          alt=""
          className="img-fluid"
          style={{ width: "600px", height: "180px" }}
        />
        <div className="job-details-compnay-profile">
          <Link to={{ pathname: `/companydetails/${company.id}` }}
            className="primary-link">
            <img
              src={
                company.logo ||
                "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
              } // Remplacez par l'URL appropriée depuis l'objet companyImages
              alt=""
              className="img-fluid rounded-3 rounded-3"
              style={{ width: "60px", height: "60px", border: "2px solid black", borderRadius: "10px" }}
            />
          </Link>
        </div>
      </div>
      <CardBody className="p-3 mt-0">
        <div className="d-flex align-items-start">
          <div className="me-auto">
            <Link
              to={{ pathname: `/companydetails/${company.id}` }}
              className="primary-link"
            >
              <h6>{company.name}</h6>
            </Link>
          </div>
          <div className="ms-auto">
            <Link to={`/jobs?companyId=${company.id}`} className="primary-link">
              <span className="badge bg-info-subtle text-success fs-12">
                {company.numberOfJobs}{" "}
                {company.numberOfJobs > 1 ? "offres" : "offre"}
              </span>
            </Link>
          </div>
        </div>
        <div className="details-container">
          <div className="text-muted">
            <i className="mdi mdi-map-marker"></i>{" "}
            {company.address ? formatAddress(company.address) : ""}
          </div>
          {/* <div className="text-muted">
                <i className="mdi mdi-account-group"></i> {"Entre 20 et 150 salariés"}
            </div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default CompanyCard;
