import api from "../Api";

const fetchCompanyDetails = async (companyId) => {
  return await api.get(`api/v1/public/candidate/company/${companyId}`);
};

const fetchJobdetails = async (jobSlug) => {
  return await api.get(`api/v1/public/candidate/jobs/${jobSlug}/slug`);
};

const fetchRecentJobs = async () => {
  return await api.get("api/v1/public/candidate/jobs/recent");
};

const fetchJobsPaged = async (page = 0, companyId = "") => {
  const url = `/api/v1/public/candidate/jobs/paged?page=${page}&size=6${
    companyId ? `&companyId=${companyId}` : ""
  }`;
  return await api.get(url);
};

const fetchSimilarJobsPaged = async (jobId) => {
  const url = `/api/v1/public/candidate/jobs/${jobId}/company/paged`;
  return await api.get(url);
};

const JobService = {
  fetchCompanyDetails,
  fetchJobdetails,
  fetchRecentJobs,
  fetchJobsPaged,
  fetchSimilarJobsPaged,
};

export default JobService;
