import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";


const LeftSideContent = ({ selectedDetails }) => {
  return (
    <React.Fragment>
      <Col lg={5}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <img
                src={selectedDetails.logo || 'https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg'}
                alt=""
                className="avatar-lg rounded-circle"
              />
              <h6 className="fs-18 mb-3 mt-4">{selectedDetails.name}</h6>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to={selectedDetails?.linkedin} className="social-link">
                    <i className="uil uil-linkedin-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={selectedDetails?.telephone} className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={selectedDetails?.telephone} className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">Aperçu du profil</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">Email</label>
                  <div>
                    <p className="text-muted mb-0">{selectedDetails?.email}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Téléphone</label>
                  <div>
                    <p className="text-muted mb-0">{selectedDetails?.telephone}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Adresse</label>
                  <div>
                    <p className="text-muted mb-0">{`${selectedDetails?.address?.city}, ${selectedDetails?.address?.country}`}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Website</label>
                  <div>
    <p className="text-muted text-break mb-0">
        <a href={selectedDetails?.website} target="_blank" rel="noopener noreferrer">
            {selectedDetails?.website}
        </a>
    </p>
</div>
                </div>
              </li>
            </ul>
          </CardBody>
          <CardBody className="p-4 border-top">
            <h6 className="fs-17 fw-semibold mb-4">Localisation</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1628067715234!5m2!1sen!2sin"
              title="title"
              style={{ width: `100%`, height: `250` }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
