import React from 'react';
import { Container } from 'reactstrap';
import CompleteProfileContent from './CompleteProfileContent';

const CompleteProfile = () => {
    document.title = "Profile | RecruTech";
    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <CompleteProfileContent />
                </Container>
            </section>
        </React.Fragment>
    )
}

export default CompleteProfile
