import React, { useEffect } from 'react';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries } from '../../../redux/jobCategory/action';

const CountryOptions = () => {
  const dispatch = useDispatch();
  const { countries } = useSelector(state => state.jobCategoryReducer);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none"
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'white'
        : isFocused
        ? '#50D3BB'
        : 'black'
    })
  };

  // Transformer les pays en options pour react-select
  const countryOptions = countries && countries.map(country => ({
    value: country.flag,  // Emoji du drapeau comme valeur
    label: country.name,
    Image: country.pngUrl
     // Concaténer l'image du drapeau et le nom du pays comme libellé
  }));

  return (
    <React.Fragment>
      <Select
        options={countryOptions || []} // Utiliser les options transformées (ou un tableau vide par défaut)
        className="choices selectForm__inner"
        placeholder="Pays"
        isMulti={false}
        styles={colourStyles}
      />
    </React.Fragment>
  );
};

export default CountryOptions;
