import {
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILURE,
  } from '../types';
  
  const initialState = {

    company: {
        content: [],
        totalPages: undefined,
        currentPage: 0,
        totalElements: undefined
    },
    loading: false,
    error: null,
  };
  
  const companyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPANIES_REQUEST:
        return { ...state, loading: true };
      case FETCH_COMPANIES_SUCCESS:
        return {
          ...state,
          loading: false,
          company: action.payload.data,
          error: null,
        };
      case FETCH_COMPANIES_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default companyReducer;
  