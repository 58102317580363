import React from 'react';

const Experiences = ({ data, onChange }) => {
  const handleChange = (section, index, field, value) => {
    const updatedSection = data[section].map((item, i) => (
      i === index ? { ...item, [field]: value } : item
    ));
    onChange(section, updatedSection);
  };

  const handleAddItem = (section) => {
    onChange(section, [...data[section], {}]);
  };

  const handleRemoveItem = (section, index) => {
    const updatedSection = data[section].filter((_, i) => i !== index);
    onChange(section, updatedSection);
  };

  const renderSection = (section) => (
    <div>
      {data[section].map((item, index) => (
        <div key={index}>
          <label>
            Titre:
            <input
              type="text"
              value={item.title || ''}
              onChange={(e) => handleChange(section, index, 'title', e.target.value)}
            />
          </label>
          <label>
            Description:
            <input
              type="text"
              value={item.description || ''}
              onChange={(e) => handleChange(section, index, 'description', e.target.value)}
            />
          </label>
          <button onClick={() => handleRemoveItem(section, index)}>Supprimer</button>
        </div>
      ))}
      <button onClick={() => handleAddItem(section)}>Ajouter</button>
    </div>
  );

  return (
    <div>
      <h3>Expériences</h3>
      {renderSection('experiences')}
      <h3>Études</h3>
      {renderSection('educations')}
      <h3>Compétences</h3>
      {renderSection('skills')}
      <h3>Certifications</h3>
      {renderSection('certifications')}
      <h3>Langues</h3>
      {renderSection('languages')}
      <h3>Loisirs</h3>
      {renderSection('hobbies')}
    </div>
  );
};

export default Experiences;
