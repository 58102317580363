import {CLEAR_MESSAGE, SET_MESSAGE} from "../types";

const initialState = {
  message: undefined
};
const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: payload
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null
      };
    default:
      return state;
  }
}
export default messageReducer;
