import React, { useEffect } from 'react';
import { Col, Row, Container, Card, CardBody, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchJobCategories } from '../../../redux/jobCategory/action';

const Categories = () => {
  const dispatch = useDispatch();

  const { jobCategories, loading } = useSelector(state => state.jobCategoryReducer);

  useEffect(() => {
    dispatch(fetchJobCategories());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <p className="badge bg-warning text-black fs-15 mb-2">Emplois par Catégories</p>
              </div>
            </Col>
          </Row>
          <Row>
            {loading ? (
              <div className="text-center mt-5">
                <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
              </div>
            ) : (
              jobCategories.map((category, key) => (
                <Col lg={4} key={key}>
                  <Card className="job-Categories-box bg-light border-0">
                    <CardBody className="p-4">
                      <ul className="list-unstyled job-Categories-list mb-0">
                        <li key={category.id}>
                          <Link to={{ pathname: `/jobcategory/${category.id}` }} className="primary-link d-flex align-items-center">
                            <img
                              src={category.webIcon || 'https://via.placeholder.com/40'}
                              alt={category.name}
                              width="40"
                              height="40"
                              className="me-2"
                            />
                            {category.name}
                            <span className="badge bg-warning text-black float-end ms-auto">
                              {category.totalJobs}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Categories;
