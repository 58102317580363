import api from "../Api"

const fetchCountries =  async () => {
    return await api.get("api/v1/public/candidate/countries");
};

const fetchJobCategories =  async () => {
    return await api.get("api/v1/public/job-categories");
};

const fetchJobByCategory =  async (categoryId, page, size = 20) => {
    return await api.get(`api/v1/public/candidate/job/category/${categoryId}/paged?page=${page}&size=${size}`);
};

const jobCategoryService = {
    fetchCountries,
    fetchJobCategories,
    fetchJobByCategory
};

export default jobCategoryService;