import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Nav, NavLink } from 'reactstrap'

//Import images
import blogImage4 from '../../../assets/images/blog/img-04.jpg';
import blogImage5 from '../../../assets/images/blog/img-05.jpg';
import blogImage6 from '../../../assets/images/blog/img-06.jpg';
import blogImage7 from '../../../assets/images/blog/img-07.jpg';
import blogImage8 from '../../../assets/images/blog/img-08.jpg';
import blogImage9 from '../../../assets/images/blog/img-09.jpg';
import blogImage10 from '../../../assets/images/blog/img-10.jpg';

const TestGridContent = () => {
    const testDomain = [
        {
            id: 1,
            testDomainImage: blogImage8,
            testDomainTitle: "Administrateur Système",
            total: 3
        },
        {
            id: 2,
            testDomainImage: blogImage9,
            testDomainTitle: "Architecte IT",
            total: 2
        },
        {
            id: 3,
            testDomainImage: blogImage10,
            testDomainTitle: "Développeur Back-End",
            total: 5
        },
        {
            id: 4,
            testDomainImage: blogImage5,
            testDomainTitle: "Développeur Full-Stack",
            total: 3
        },
        {
            id: 5,
            testDomainImage: blogImage6,
            testDomainTitle: "Devops",
            total: 2
        },
        {
            id: 6,
            testDomainImage: blogImage7,
            testDomainTitle: "Développeur Mobile",
            total: 5
        }

    ]

    const testTech = [
        {
            id: 1,
            testDomainImage: blogImage4,
            testDomainTitle: "Administrateur Système",
            total: 3
        },
        {
            id: 2,
            testDomainImage: blogImage9,
            testDomainTitle: "Architecte IT",
            total: 2
        },
        {
            id: 3,
            testDomainImage: blogImage10,
            testDomainTitle: "Développeur Back-End",
            total: 5
        },
        {
            id: 4,
            testDomainImage: blogImage5,
            testDomainTitle: "Développeur Full-Stack",
            total: 3
        },
        {
            id: 5,
            testDomainImage: blogImage6,
            testDomainTitle: "Devops",
            total: 2
        },
        {
            id: 6,
            testDomainImage: blogImage7,
            testDomainTitle: "Développeur Mobile",
            total: 5
        }

    ]
    return (
        <React.Fragment>
            <section className="section mt-0">
                <Container>
                    <Row className="align-items-center">
                        <div className="section-title me-5">
                            <Nav className="process-menu  flex-column nav-pills">
                                <NavLink to="#">
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0"><i className="mdi mdi-monitor-cellphone fs-20"></i></div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Tests techniques par métiers tech</h5>
                                            <p className="text-muted">Les métiers de la tech nécessitent de nombreuses compétences.
                                                Retrouvez dans cette catégorie nos recommendations de tests techniques triées par métiers.</p>
                                            <Row >
                                                {(testDomain).map((testDomainDetails, key) => (
                                                    <Col lg={4} className="mt-4" key={key}>
                                                        <Card className="card blog-modern-box overflow-hidden border-0" >
                                                            <img src={testDomainDetails.testDomainImage} alt="" className="img-fluid" style={{ width: '300px', height: '170px' }} />
                                                            <div className="bg-overlay"></div>
                                                            <div className="card-img-overlay">
                                                                <Link to="/#" className="text-white"><h5 className="card-title">{testDomainDetails.testDomainTitle}</h5></Link>
                                                                <p className="card-text text-white-50"> <Link to="/#" className="text-white-50 fw-bold"><span className="text-warning fw-bold">{testDomainDetails.total} - Tests</span></Link></p>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </div>
                                </NavLink>
                                <NavLink to="#">
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0"><i className="mdi mdi-hammer-screwdriver fs-20"></i></div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Tests techniques par technologie</h5>
                                            <p className="text-muted">Retrouvez nos meilleurs QCM pour chaque technologie :
                                                Javascript, React, Java, .NET, PHP... Du front-end au back-end, recherchez votre technologie favorite.</p>
                                            <Row >
                                            {(testTech).map((testTechDetails, key) => (
                                                    <Col lg={4} className="mt-4" key={key}>
                                                        <Card className="card blog-modern-box overflow-hidden border-0" >
                                                            <img src={testTechDetails.testDomainImage} alt="" className="img-fluid" style={{ width: '300px', height: '170px' }} />
                                                            <div className="bg-overlay"></div>
                                                            <div className="card-img-overlay">
                                                                <Link to="/#" className="text-white"><h5 className="card-title">{testTechDetails.testDomainTitle}</h5></Link>
                                                                <p className="card-text text-white-50"> <Link to="/#" className="text-white-50 fw-bold"><span className="text-warning fw-bold">{testTechDetails.total} - Tests</span></Link></p>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </div>
                                </NavLink>
                            </Nav>
                        </div>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    )
}

export default TestGridContent
