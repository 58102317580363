import jobCategoryService from '../../../service/jobCategory';
import { 
  FETCH_JOB_CATEGORIES_FAILURE, FETCH_JOB_CATEGORIES_REQUEST, FETCH_JOB_CATEGORIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS,
  FETCH_JOB_BY_CATEGORY_FAILURE, FETCH_JOB_BY_CATEGORY_REQUEST, FETCH_JOB_BY_CATEGORY_SUCCESS } from '../types';

  export const fetchCountries = () => {
    return async (dispatch) => {
      dispatch({ type: FETCH_COUNTRIES_REQUEST });
      try {
        const response = await jobCategoryService.fetchCountries();
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: response.data });
        }
      } catch (error) {
        dispatch({ type: FETCH_COUNTRIES_FAILURE, payload: error.message });
      }
    };
  };

  export const fetchJobCategories = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_JOB_CATEGORIES_REQUEST });
    try {
      const response = await jobCategoryService.fetchJobCategories();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: FETCH_JOB_CATEGORIES_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: FETCH_JOB_CATEGORIES_FAILURE, payload: error.message });
    }
  };
};

export const fetchJobByCategory = (categoryId, page) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_JOB_BY_CATEGORY_REQUEST });
    try {
      console.log('dddd');
      const response = await jobCategoryService.fetchJobByCategory(categoryId, page);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: FETCH_JOB_BY_CATEGORY_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: FETCH_JOB_BY_CATEGORY_FAILURE, payload: error.message });
    }
  };
};
