import React from 'react';

const Review = ({ data }) => {
  return (
    <div>
      <h3>Réviser vos informations</h3>
      <h4>Mes renseignements</h4>
      <p>Nom: {data.personalInfo.firstName}</p>
      <p>Prénom: {data.personalInfo.lastName}</p>
      <p>Adresse: {data.personalInfo.address}</p>
      <p>Email: {data.personalInfo.email}</p>
      <p>Téléphone: {data.personalInfo.phone}</p>
      {data.experiences.length > 0 && (
        <div>
          <h4>Expériences</h4>
          {data.experiences.map((exp, index) => (
            <div key={index}>
              <p>{exp.title}</p>
              <p>{exp.description}</p>
            </div>
          ))}
        </div>
      )}
      {/* Ajoutez les autres sections de la même manière */}
      <p>Divulgation volontaire acceptée: {data.voluntaryDisclosure ? 'Oui' : 'Non'}</p>
    </div>
  );
};

export default Review;
