import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { formatAddress } from "../../../utils";
import { useDispatch } from "react-redux";
import { fetchJobDetails } from "../../../redux/job/action";

const JobCardItem = ({ job, firstCardRef }) => {
  const dispatch = useDispatch();

  const handleJobClick = (selectedDetails) => {
    dispatch(fetchJobDetails(selectedDetails));
  };

  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  return (
    <Col md={6} ref={firstCardRef}>
      <div className="job-box bookmark-post card mt-4">
        <div className="bookmark-label text-center">
          <Link to="#" className="align-middle text-white">
            <i className="mdi mdi-star"></i>
          </Link>
        </div>
        <div className="p-4">
          <Row className="align-items-center mb-1">
            <Col md={8}>
              <div className="text-start">
                <h5 className="fs-18 mb-1">
                  <Link
                    to={{ pathname: `/jobdetails/${job.slug}` }}
                    className="primary-link"
                    onClick={() => handleJobClick(job)}
                  >
                    {job.title}
                  </Link>
                </h5>
              </div>
            </Col>
            <Col md={3}>
              <div className="text-end mb-4">
                <Link to="/companydetails">
                  <img
                    src={
                      job.company.logo ||
                      "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                    }
                    alt=""
                    className="img-fluid rounded-3"
                    width={50}
                    height={50}
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className=" text-align-justify mb-0">
            <Col md={7}>
              <div
                style={{ textAlign: "justify" }}
                className="text-start mb-0 mb-md-0"
              >
                <p className="text-muted fs-15 mb-0">{job.description}</p>
              </div>
            </Col>
            <Col md={5}>
              <div className=" text-end mb-0 mb-md-0">
                <p className="text-muted fs-14 mb-0">{job.company.name}</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <div className="text-end">
              <div className="ms-0">
                {job.contractType.map((contract) => (
                  <span key={contract} className={getBadgeClass(contract)}>
                    {contract}
                  </span>
                ))}
              </div>
            </div>
          </Row>
        </div>
        <div className="p-3 bg-light">
          <Row className="justify-content-between">
            <Col md={8}>
              <div className="d-flex mb-0">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-map-marker text-primary me-1"></i>
                </div>
                <p className="text-muted mb-0">{formatAddress(job.address)}</p>
              </div>
            </Col>
            <Col lg={4} md={3}>
              <div className="d-flex mb-0">
                <div className="flex-shrink-0">
                  <i className="uil uil-clock-three text-primary me-1"></i>
                </div>
                <p className="text-muted mb-0">{job.durationSincePosting}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default JobCardItem;
