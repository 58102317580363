import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { fr } from "date-fns/locale"; // Importez la locale française
import { format } from "date-fns"; // Importer la fonction de formatage
import "./MasonaryContent.css"; // Assurez-vous d'importer le fichier CSS

const MasonaryContent = ({ training, firstCardRef }) => {
  const coverPhoto =
    training.coverPhoto ||
    "https://res.cloudinary.com/dryskp7hv/image/upload/v1723497638/e737f542-b510-44d9-95c8-2970f03975b9.png"; // Photo de couverture par défaut si non définie

  const authorImage =
    training.authorImage ||
    "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png";

  // Formater la date de début
  const formattedStartDate = format(
    new Date(training.startDate),
    "dd MMMM yyyy",
    { locale: fr }
  );

  return (
    <Col lg={4} md={6} ref={firstCardRef} className="mb-4">
      <Card className="blog-masonry-box shadow overflow-hidden border-0 p-2">
        <div className="card-header">
          <Link to={`/formation-details/${training.slug}`} className="img-link">
            <img src={coverPhoto} alt={training.title} className="img-fluid" />
          </Link>
        </div>
        <CardBody className="p-4">
          <Link
            to={`/formation-details/${training.slug}`}
            className="primary-link"
          >
            <h6>{training.title}</h6>
          </Link>
          <div className="d-flex align-items-center mt-4">
            <div className="flex-shrink-0">
              <img
                src={authorImage}
                alt={training.authorName}
                className="avatar-xs rounded-circle"
                style={{
                  border: "3px solid #28a745", // Couleur verte avec une largeur de 3px
                  padding: "2px", // Espacement entre l'image et la bordure
                  borderRadius: "50%", // Assurez-vous que la bordure suit la forme circulaire de l'image
                }}
              />
            </div>
            <div className="ms-3">
              <h6 className="fs-14 mb-1">{training.authorName}</h6>
              <p className="text-muted fs-14 mb-0">Formateur</p>
            </div>
          </div>
          <p className="text-muted fs-14 mt-2 mb-2">
            <b>Débute le</b> <i className="mdi mdi-circle-medium"></i>{" "}
            {formattedStartDate}
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MasonaryContent;
