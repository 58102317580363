import React, { useEffect, useRef, useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import Masonry from 'react-masonry-component';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobByCategory } from '../../../redux/jobCategory/action';
import Pagination from '../../CandidateAndCompany/CompanyList/Pagination';
import JobCardItem from './JobCardItem';

const JobVacancy = () => {
  const firstCardRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  
  const { jobByCategory, loading } = useSelector(state => state.jobCategoryReducer);

  // Utilisez useState pour définir la valeur par défaut de la page à 0
  const [currentPage, setCurrentPage] = useState(0);

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    dispatch(fetchJobByCategory(id, page));
  };

  useEffect(() => {
    // Utilisez la valeur de la page actuelle ici
    dispatch(fetchJobByCategory(id, currentPage));
    window.scrollTo(0, 0);
  }, [dispatch, currentPage, id]);

  return (
    <React.Fragment>
      <Container>
        {loading ? (
          <div className="text-center mt-5">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          </div>
        ) : (
          <Masonry className="row">
            {jobByCategory && jobByCategory.content && jobByCategory.content.map((item, index) => (
              <JobCardItem key={item.id} job={item} index={index} firstCardRef={index === 0 ? firstCardRef : null} />
            ))}
          </Masonry>
        )}
        <Pagination
          currentPage={jobByCategory ? jobByCategory.currentPage : 0}
          totalPages={jobByCategory ? jobByCategory.totalPages : 0}
          fetchData={fetchJobByCategory}
          setCurrentPage={setCurrentPageHandler}  // Utilisez la nouvelle fonction définie
          firstCardRef={firstCardRef}
        />
      </Container>
    </React.Fragment>
  );
};

export default JobVacancy;
