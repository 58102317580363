import React from "react";
import Select from "react-select";

const JobType = () => {
  const options = [
    { label: "CDI", value: "1" },
    { label: "Stage", value: "2" },
    { label: "CDD / Temporaire", value: "3" },
    { label: "Alternance", value: "4" },
    { label: "Freelance", value: "5" },
    { label: "Autres", value: "5" }
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled
          ? '#ccc' // Couleur du texte pour les options désactivées
          : isSelected
          ? 'white' // Couleur du texte pour les options sélectionnées
          : isFocused
          ? '#50D3BB' // Couleur du texte pour les options survolées
          : 'black' // Couleur du texte pour les options normales
      };
    }
  };

  return (
    <React.Fragment>
      <Select
        options={options}
        styles={colourStyles}
        className="selectForm__inner"
        data-trigger
        placeholder="Type d'emploi"
        isMulti  // Active le mode choix multiple
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
      />
    </React.Fragment>
  );
};

export default JobType;
