import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Skeleton } from "antd";

const JobDetailsSkeleton = () => {
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <Skeleton.Image style={{ width: "100%", height: 200 }} />
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <Skeleton active title={false} paragraph={{ rows: 1, width: '70%' }} />
                <Skeleton.Button style={{ width: 100, marginTop: 8 }} active />
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={4}>
                <Skeleton.Input style={{ width: "100%" }} active />
              </Col>
              <Col lg={6}>
                <Skeleton.Input style={{ width: "100%" }} active />
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">
              <Skeleton.Input style={{ width: 200 }} active />
            </h5>
            <Skeleton paragraph={{ rows: 3 }} active />
          </div>

          <div className="mt-4">
            <h5 className="mb-3">
              <Skeleton.Input style={{ width: 200 }} active />
            </h5>
            <Skeleton paragraph={{ rows: 3 }} active />
          </div>

          <div className="mt-4">
            <h5 className="mb-3">
              <Skeleton.Input style={{ width: 200 }} active />
            </h5>
            <Skeleton paragraph={{ rows: 3 }} active />
            <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              <Skeleton.Button style={{ width: 80 }} active />
              <Skeleton.Button style={{ width: 80 }} active />
              <Skeleton.Button style={{ width: 80 }} active />
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">
              <Skeleton.Input style={{ width: 200 }} active />
            </h5>
            <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              <Skeleton.Button style={{ width: 80 }} active />
              <Skeleton.Button style={{ width: 80 }} active />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsSkeleton;
