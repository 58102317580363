import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
// import { Link } from "react-router-dom";

const JobDetailsDescription = ({ selectedDetails }) => {
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);

  // Si selectedDetails est undefined, renvoyez null pour ne rien afficher
  if (!selectedDetails) {
    return null;
  }

  // Fonction de gestion du chargement de l'image de couverture
  const handleCoverImageLoad = () => {
    setIsCoverLoaded(true);
  };

  // Fonction pour afficher du HTML en toute sécurité
  const renderHTMLContent = (htmlContent) => {
    return { __html: htmlContent };
  };

  const renderResponsibilities = () => {
    if (!selectedDetails.responsibility) return null;

    return (
      <div className="job-detail-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetails.responsibility
          )}
        />
      </div>
    );
  };

  const renderQualifications = () => {
    if (!selectedDetails.qualification) return null;

    return (
      <div className="job-detail-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetails.qualification
          )}
        />
      </div>
    );
  };

  const renderTechnicalSkills = () => {
    if (!selectedDetails.technicalSkills) return null;

    return (
      <div className="job-details-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetails.technicalSkills
          )}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <div>
          <img
            src={selectedDetails.company.coverPhoto || JobDetailImage}
            alt=""
            width="100%"
            height={80}
            className="img-fluid"
            onLoad={handleCoverImageLoad} // Déclenchement après le chargement de l'image
          />
          {/* Le logo ne s'affiche que lorsque l'image de couverture est chargée */}
          {isCoverLoaded && (
            <div className="job-details-compnay-profile">
              <img
                src={
                  selectedDetails.company.logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                alt=""
                width="100"
                height="100"
                style={{ border: "2px solid black", borderRadius: "10px" }}
                className="img-fluid rounded-3"
              />
            </div>
          )}
        </div>
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <h5 className="mb-1">{selectedDetails.title}</h5>
                <ul className="list-inline text-muted mb-0">
                  <li className="list-inline-item">
                    <i className="mdi mdi-account"></i>{" "}
                    {selectedDetails.positionAvailable} Poste
                    {selectedDetails.positionAvailable > 1 ? "s" : ""} à
                    pourvoir
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={4}>
                <div className="border rounded-start p-3">
                  <p className="text-black mb-0 fs-13">
                    Experience :
                    <span className="fs-12 fw-bold">
                      {" "}
                      {selectedDetails.experience} An(s)
                    </span>
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="border p-3">
                  <p className="text-black fs-13 mb-0">
                    Contrat :
                    {selectedDetails.contractTypes.map((contract) => (
                      <span key={contract} className="fs-12 fw-bold ms-2">
                        {contract.name}
                      </span>
                    ))}
                  </p>
                </div>
              </Col>
              {/* <Col lg={4}>
                <div className="border rounded-end p-3">
                  <p className="text-black fs-13 mb-0">
                    Salaire :
                    <span className="fs-12 fw-bold">
                      {` ${selectedDetails.minSalary} - ${selectedDetails.maxSalary} ${selectedDetails.currency}`}
                    </span>
                  </p>
                </div>
              </Col> */}
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Description de l'offre</h5>
            <div
              className="job-detail-desc"
              dangerouslySetInnerHTML={renderHTMLContent(
                selectedDetails.description
              )}
            />
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Responsabilités</h5>
            {renderResponsibilities()}
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Qualification </h5>
            {renderQualifications()}
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Compétences Techniques</h5>
            {renderTechnicalSkills()}
            <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              {selectedDetails.skills.map((skill, index) => (
                <span key={index} className="badge bg-primary">
                  {skill.name}
                </span>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Langues parlées</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                {selectedDetails.languages?.length > 0 && (
                  <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                    {selectedDetails.languages.map((language, index) => (
                      <span key={index} className="badge bg-primary">
                        {language.name}
                      </span>
                    ))}
                  </div>
                )}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
