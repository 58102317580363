import React, { useEffect } from "react";
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import { Rate } from "antd";
import Section from "../Section";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCandidateExperiences,
  fetchCandidateEducations,
  fetchCandidateSkills,
  fetchCandidateLanguages,
  fetchCandidateInfoPerso,
} from "../../../../redux/profile/action";

const PdfGenerator = () => {
  // const personalInfo = {
  //   name: "John",
  //   surname: "Doe",
  //   email: "john.doe@example.com",
  //   phone: "+33 6 12 34 56 78",
  // };
  const dispatch = useDispatch();

  // Charger les informations depuis le store Redux
  const {
    allExperiences,
    allEducations,
    allSkills,
    allLanguages,
    loading,
    infoPersoDetails,
  } = useSelector((state) => state.profileReducer);
  useEffect(() => {
    // Dispatch des actions pour charger les données nécessaires
    dispatch(fetchCandidateExperiences());
    dispatch(fetchCandidateEducations());
    dispatch(fetchCandidateSkills());
    dispatch(fetchCandidateLanguages());
    dispatch(fetchCandidateInfoPerso());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const options = { month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const generatePDF = async () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      hotfixes: ["px_scaling"],
    });
    const content = document.getElementById("pdf-content");

    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL("image/png");

    // Marges en mm
    const marginLeft = 10;
    const marginRight = 10;
    const marginTop = 10;
    const marginBottom = 10;

    const imgWidth =
      pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    const pageHeight =
      pdf.internal.pageSize.getHeight() - marginTop - marginBottom;
    let heightLeft = imgHeight;
    let position = marginTop;

    pdf.addImage(imgData, "PNG", marginLeft, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + marginTop;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", marginLeft, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("Recrutech_CV.pdf");
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={10}>
              <Card className="profile-content-page mt-4 mt-lg-0">
                <CardBody className="p-4">
                  <div id="pdf-content">
                    <div className="text-center mb-3">
                      <h3>
                        <strong style={{ textTransform: "uppercase" }}>
                          <span>
                            {infoPersoDetails?.data.user.firstName}{" "}
                            {infoPersoDetails?.data.user.lastName}
                          </span>
                        </strong>
                      </h3>
                      <p>
                        <strong>{infoPersoDetails?.data.user.login}</strong>
                      </p>
                      <p>
                        <strong>{infoPersoDetails?.data.whatsapp}</strong>
                      </p>
                    </div>
                    <div className="mt-4">
                      <h5 className="fs-18 fw-bold text-primary">
                        <i className="uil uil-file-bookmark-alt fs-22"></i>{" "}
                        COMPETENCE(S){" "}
                      </h5>
                      <hr />
                    </div>

                    <div className="mt-0 mb-5">
                      {allSkills?.content?.length > 0 && (
                        <table className="table table-inline mb-0">
                          <tbody>
                            {allSkills.content.map((skill) => (
                              <tr key={skill.id}>
                                <td>{skill.fieldOfCompetence}</td>
                                <td>
                                  {skill.competence}&nbsp;&nbsp;
                                  <Rate
                                    disabled
                                    value={skill.experienceLevel.id}
                                    count={4}
                                    style={{ color: "#50D3BB" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="candidate-education-details">
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-briefcase fs-22"></i>{" "}
                        EXPERIENCE(S){" "}
                      </h6>
                      <hr />
                      {allExperiences?.content?.length > 0 &&
                        allExperiences.content.map((experience) => (
                          <div key={experience.id} className="mt-4 d-flex">
                            <Row className="align-items-start">
                              <Col lg={4}>
                                <p className="mb-1 fw-bold text-primary">
                                  {experience.company} - (
                                  {formatDate(experience.startDate)} -{" "}
                                  {formatDate(experience.endDate)})
                                </p>
                              </Col>
                              <Col lg={8} className="text-start">
                                <h6 className="fs-16 mb-1 text-uppercase">
                                  <strong>{experience.position}</strong>
                                </h6>
                                <p className="text-primary">Context</p>
                                {experience.context && (
                                  <p className="text-black fs-16">
                                    {experience.context}
                                  </p>
                                )}
                                {experience.actions && (
                                  <>
                                    <p className="text-primary">Missions</p>
                                    <ul className="text-black fs-16">
                                      {experience.actions
                                        .split("\n")
                                        .map((action, index) => (
                                          <li key={index}>{action.trim()}</li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                                {experience.results && (
                                  <>
                                    <p className="text-primary">Objectifs</p>
                                    <ul className="text-black fs-16">
                                      {experience.results
                                        .split("\n")
                                        .map((result, index) => (
                                          <li key={index}>{result.trim()}</li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                                {experience.technicalEnvironment && (
                                  <>
                                    <p className="text-primary">
                                      Environnement technique
                                    </p>
                                    <p className="text-black fs-16">
                                      {experience.technicalEnvironment
                                        .replace(/\n/g, ", ")
                                        .trim()}
                                    </p>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>

                    <div className="candidate-education-details">
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-graduation-cap fs-22"></i>{" "}
                        FORMATION(S){" "}
                      </h6>
                      <hr />
                      {allEducations?.content?.length > 0 &&
                        allEducations.content.map((education) => (
                          <div key={education.id} className="mt-4 d-flex">
                            <Row className="align-items-start">
                              <Col lg={4}>
                                <p className="mb-1 fw-bold text-primary">
                                  {education.school} - (
                                  {formatDate(education.startDate)} -{" "}
                                  {formatDate(education.endDate)})
                                </p>
                              </Col>
                              <Col lg={8} className="text-start">
                                <h6 className="fs-16 mb-1 text-uppercase">
                                  <strong>{education.name}</strong>
                                </h6>
                                <p className="text-black fs-16">
                                  {education.summary}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>

                    <div className="candidate-education-details">
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-translate fs-22"></i> LANGUE(S){" "}
                      </h6>
                      <hr />
                      {allLanguages?.content?.length > 0 &&
                        allLanguages.content.map((language) => (
                          <div key={language.id} className="mt-4">
                            <Row className="align-items-start">
                              <Col lg={5}>
                                <p className="mb-1 fw-bold text-uppercase">
                                  {language.language.name}
                                </p>
                              </Col>
                              <Col lg={5} className="text-start">
                                <Rate
                                  disabled
                                  value={language.level}
                                  count={4}
                                  style={{ color: "#50D3BB" }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={2}>
              <button onClick={generatePDF} className="btn btn-primary">
                <i className="uil uil-import"> </i>
                Télécharger
              </button>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PdfGenerator;
