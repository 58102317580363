import axios from "axios";
import TokenService from "./TokenService"; // Assurez-vous d'importer le TokenService
import authService from "../service/auth";

const baseURL = "https://api.recrutech.pro/";
// const baseURL = "http://ip172-18-0-90-csbnqqi91nsg00cfhs30-8080.direct.labs.play-with-docker.com/";

const instance = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
  },
});

// Interception des réponses pour gérer la déconnexion automatique
instance.interceptors.response.use(
  (response) => {
    // Si la réponse est réussie, retourner la réponse
    return response;
  },
  (error) => {
    // Si la réponse retourne une erreur 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      // Supprimer le token et déconnecter l'utilisateur
      TokenService.removeUser();
      authService.signOut();
      // window.location.href = "/"; // Rediriger vers la page de connexion
    }

    // Rejeter l'erreur pour la gérer dans les appels API
    return Promise.reject(error);
  }
);

export default instance;
