import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import PersonalInfo from './PersonalInfo';
import Experiences from './Experiences';
import VoluntaryDisclosure from './VoluntaryDisclosure';
import Review from './Review';
import './MultiStepForm.css'; // Add this CSS file

const MultiStepForm = ({ applicationType }) => {
    const { selectedDetails } = useSelector(state => state.jobReducer);

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    personalInfo: {
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phone: ''
    },
    experiences: [],
    educations: [],
    skills: [],
    certifications: [],
    languages: [],
    hobbies: [],
    voluntaryDisclosure: false
  });

  const handleNextStep = () => {
    setCurrentStep(prev => prev + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleChange = (section, data) => {
    setFormData(prev => ({
      ...prev,
      [section]: data
    }));
  };

  const steps = [
    {
      title: 'Mes renseignements',
      component: (
        <PersonalInfo
          data={formData.personalInfo}
          onChange={(data) => handleChange('personalInfo', data)}
        />
      )
    },
    applicationType === 'manual' && {
      title: 'Mes expériences',
      component: (
        <Experiences
          data={formData}
          onChange={(section, data) => handleChange(section, data)}
        />
      )
    },
    {
      title: 'Divulgations volontaires',
      component: (
        <VoluntaryDisclosure
          data={formData.voluntaryDisclosure}
          onChange={(data) => handleChange('voluntaryDisclosure', data)}
        />
      )
    },
    {
      title: 'Réviser',
      component: <Review data={formData} />
    }
  ].filter(Boolean);

  return (
    <>
    <br />
    <div className="multi-step-form-container mt-5">
    <p className="text-muted text-center mb-0 fs-20 mt-5">(CAN) {selectedDetails.title}</p>
          <div className="progress-container">
              <div className="progress-bar" style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}></div>
              <div className="progress-steps">
                  {steps.map((step, index) => (
                      <div key={index} className="progress-step-container">
                          <div className={`progress-step ${index <= currentStep ? 'completed' : ''}`}></div>
                          <span className="step-label">{step.title}</span>
                      </div>
                  ))}
              </div>
          </div>
          {/* <h2>{steps[currentStep].title}</h2> */}
          {steps[currentStep].component}
          <div className="navigation-buttons">
              {currentStep > 0 && (
                  <Button onClick={handlePrevStep}>Précédent</Button>
              )}
              {currentStep < steps.length - 1 ? (
                  <Button onClick={handleNextStep}>Suivant</Button>
              ) : (
                  <Button onClick={() => alert('Formulaire soumis')}>Soumettre</Button>
              )}
          </div>
      </div></>
  );
};

export default MultiStepForm;
