import React from "react";
import { Calendar } from "antd";
// import "antd/dist/antd.css";

const CalendarComponent = () => {
  // You can add any necessary state or logic here

  return (
    <div style={{ width: 600, border: "1px solid #d9d9d9", borderRadius: 4 }}>
      <Calendar />
    </div>
  );
};

export default CalendarComponent;
