import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button as ReactstrapButton,
} from "reactstrap";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLevelTypes,
  addCandidateSkill,
  fetchCandidateSkillById,
  updateCandidateSkill,
  resetSelectedDetails,
} from "../../../../redux/profile/action";
import { toast, ToastContainer } from "react-toastify";

const SkillForm = ({ selectedSkill, onCancel }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const [skill, setSkill] = useState({
    competence: "",
    fieldOfCompetence: "",
    experienceLevelId: "",
    summaryCompetence: "",
  });

  const dispatch = useDispatch();
  const { levelTypes, selectedDetails } = useSelector(
    (state) => state.profileReducer
  );

  const transformSkillData = (selectedDetails) => {
    return {
      id: selectedDetails.id,
      competence: selectedDetails.competence || "",
      fieldOfCompetence: selectedDetails.fieldOfCompetence || "",
      experienceLevelId: selectedDetails.experienceLevel?.id || "",
      summaryCompetence: selectedDetails.summaryCompetence || "",
    };
  };

  // Charger les détails de la compétence si une compétence est sélectionnée
  useEffect(() => {
    dispatch(fetchLevelTypes());
    const loadSkill = async () => {
      if (selectedSkill) {
        dispatch(fetchCandidateSkillById(selectedSkill.id));
      }
    };
    loadSkill();
  }, [dispatch, selectedSkill]);

  useEffect(() => {
    if (selectedDetails) {
      const formattedSkills = transformSkillData(selectedDetails);
      setSkill(formattedSkills);
    } else if (selectedSkill) {
      const formattedSkills = transformSkillData(selectedSkill);
      setSkill(formattedSkills);
    } else {
      // Réinitialiser le formulaire si ni selectedDetails ni selectedSkill ne sont disponibles
      resetForm();
    }
  }, [selectedDetails, selectedSkill]);

  const handleChange = (e) => {
    setSkill({ ...skill, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedSkill?.id) {
      dispatch(updateCandidateSkill(skill))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la modification !");
        });
    } else {
      dispatch(addCandidateSkill(skill))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de l'ajout !");
        });
    }
  };

  // Fonction pour réinitialiser le formulaire
  const resetForm = () => {
    setSkill({
      competence: "",
      fieldOfCompetence: "",
      experienceLevelId: "",
      summaryCompetence: "",
    });
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="competence">Compétence*</Label>
                <Input
                  type="text"
                  name="competence"
                  id="competence"
                  value={skill.competence}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="fieldOfCompetence">Domaine de compétence*</Label>
                <Input
                  type="text"
                  name="fieldOfCompetence"
                  id="fieldOfCompetence"
                  value={skill.fieldOfCompetence}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="experienceLevelId">Niveau</Label>
                <select
                  className="form-select"
                  name="experienceLevelId"
                  id="experienceLevelId"
                  value={skill.experienceLevelId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Sélectionnez votre niveau</option>
                  {levelTypes &&
                    levelTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label>
              <a
                href="#"
                className="text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreInfo(!showMoreInfo);
                }}
              >
                {showMoreInfo ? (
                  <>
                    <DownOutlined /> Précision
                  </>
                ) : (
                  <>
                    <RightOutlined /> Précision
                  </>
                )}
              </a>
            </Label>
          </FormGroup>

          {showMoreInfo && (
            <>
              <hr />
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="summaryCompetence">
                      Description de votre compétence
                    </Label>
                    <textarea
                      className="form-control"
                      id="summaryCompetence"
                      name="summaryCompetence"
                      value={skill.summaryCompetence}
                      onChange={handleChange}
                      rows="3"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <div className="text-end">
            <ReactstrapButton type="submit" color="danger" className="me-2">
              {selectedSkill?.id ? "Modifier" : "Ajouter"} la compétence
            </ReactstrapButton>
            <ReactstrapButton
              color="primary"
              onClick={() => {
                resetForm();
                dispatch(resetSelectedDetails());
                onCancel();
              }}
              className="ms-2"
            >
              Annuler
            </ReactstrapButton>
          </div>
        </Form>
        <ToastContainer />
      </CardBody>
    </Card>
  );
};

export default SkillForm;
