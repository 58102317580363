import React, { useState, useEffect } from "react";
import resetPasswordImage from "../../assets/images/auth/reset-password.png";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { resetPasswordFinish, clearError } from "../../redux/auth/action";
import { clearMessage } from "../../redux/message/action";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

const NewPassword = () => {
  document.title = "New Password | Recrutech";

  const [newPassword, setNewPassword] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Ajout pour afficher/masquer le mot de passe
  const [loading, setLoading] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false); // État pour vérifier la validité du mot de passe

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (activationSuccess) {
      navigate("/"); // Naviguer uniquement si l'activation est réussie
    }
  }, [activationSuccess, navigate]);

  // Fonction de validation du mot de passe
  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMinLength = password.length >= 8;
    return hasUppercase && hasSpecialChar && hasMinLength;
  };

  const handleNewPasswordChange = (e) => {
    dispatch(clearMessage());
    const password = e.target.value;
    setNewPassword(password);
    setPasswordValid(validatePassword(password)); // Valider le mot de passe
  };

  const handleOtpNumberChange = (e) => {
    dispatch(clearMessage());
    setOtpNumber(e.target.value);
  };

  const submitVerification = async () => {
    setLoading(true);
    const result = await dispatch(resetPasswordFinish(otpNumber, newPassword));
    if (result.success) {
      setActivationSuccess(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(clearError());
    dispatch(clearMessage());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src="https://res.cloudinary.com/dryskp7hv/image/upload/v1688758187/Eburnie%20hub/logo/rt_couleur_simple_480_dh81k5.png"
                                height="100"
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-0">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-black">
                            <div className="text-center mb-4">
                              <h5>Enregistrer un nouveau mot de passe</h5>
                            </div>
                            <Form className="auth-form text-black">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}
                                Entrez le code reçu par Email et votre nouveau
                                mot de passe!{" "}
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="otpNumberInput"
                                  className="form-label"
                                >
                                  Code Otp
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  value={otpNumber}
                                  onChange={handleOtpNumberChange}
                                  id="otpNumberInput"
                                  placeholder="Entrer le code reçu par Email"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="newPasswordInput"
                                  className="form-label"
                                >
                                  Nouveau mot de passe
                                </label>
                                <div className="input-group">
                                  <Input
                                    type={showPassword ? "text" : "password"} // Alterner le type d'entrée
                                    className="form-control"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    id="newPasswordInput"
                                    placeholder="Entrer votre nouveau mot de passe"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setShowPassword(!showPassword)} // Bouton pour afficher/masquer
                                  >
                                    {showPassword ? "Masquer" : "Afficher"}
                                  </button>
                                </div>
                                {!passwordValid && newPassword && (
                                  <small className="text-danger">
                                    Le mot de passe doit contenir au moins 8
                                    caractères, une majuscule et un caractère
                                    spécial.
                                  </small>
                                )}
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  onClick={submitVerification}
                                  className="btn btn-primary w-100"
                                  disabled={!passwordValid || loading} // Désactiver si le mot de passe n'est pas valide
                                >
                                  {loading ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "Soumettre"
                                  )}
                                </button>
                              </div>
                            </Form>
                            <div className="mt-5 text-center text-black-50">
                              <p>
                                Vous vous en souvenez ?{" "}
                                <Link
                                  to="/"
                                  className="fw-medium text-primary text-decoration-underline"
                                >
                                  {" "}
                                  Retour à l'accueil{" "}
                                </Link>
                              </p>
                            </div>
                            <ToastContainer />
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewPassword;
