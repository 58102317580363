import React from 'react';

const PersonalInfo = ({ data, onChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...data, [name]: value });
  };

  return (
    <div>
      <label>
        Nom:
        <input
          type="text"
          name="firstName"
          value={data.firstName}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Prénom:
        <input
          type="text"
          name="lastName"
          value={data.lastName}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Adresse:
        <input
          type="text"
          name="address"
          value={data.address}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={data.email}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Téléphone:
        <input
          type="tel"
          name="phone"
          value={data.phone}
          onChange={handleChange}
          required
        />
      </label>
    </div>
  );
};

export default PersonalInfo;
