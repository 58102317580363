import React, { useState } from "react";
import { Collapse } from "reactstrap";

const AccordianContentLeft = () => {
  const [isCollapseFirst, setIsCollapseFirst] = useState(true);
  const toggleFirst = () => setIsCollapseFirst(!isCollapseFirst);

  const [isCollapseSecond, setIsCollapseSecond] = useState(false);
  const toggleSecond = () => setIsCollapseSecond(!isCollapseSecond);

  const [isCollapseThird, setIsCollapseThird] = useState(false);
  const toggleThird = () => setIsCollapseThird(!isCollapseThird);

  return (
    <React.Fragment>
      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingone">
          <button
            className="accordion-button"
            onClick={toggleFirst}
            type="button"
          >
            Qu'est-ce que Recrutech ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseFirst} id="buying-one">
          <div className="accordion-body">
            Recrutech est une plateforme en ligne dédiée au recrutement qui permet aux candidats de rechercher des offres d'emploi,
            de postuler à des postes vacants et de se connecter avec des entreprises à la recherche de talents.
          </div>
        </Collapse>
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingtwo">
          <button
            className="accordion-button"
            onClick={toggleSecond}
            type="button"
          >
            Comment fonctionne le processus de recherche d'emploi sur Recrutech ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseSecond} id="buying-two">
          <div className="accordion-body">
            Sur Recrutech, les utilisateurs peuvent parcourir les offres d'emploi disponibles en utilisant des filtres de recherche pour trouver des postes correspondant à leurs compétences et intérêts.
            Ils peuvent ensuite postuler directement via la plateforme.
          </div>
        </Collapse>
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingthree">
          <button
            className="accordion-button"
            onClick={toggleThird}
            type="button"
          >
            Comment mettre à jour mon CV et mes informations personnelles sur Recrutech ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseThird} id="buying-three">
          <div className="accordion-body">
            Connectez-vous à votre compte Recrutech et accédez à la section 'Profil' pour mettre à jour votre CV,
            vos informations de contact et toute autre information pertinente pour les employeurs.
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default AccordianContentLeft;
