import api from "../Api";
import TokenService from "../TokenService"; // Assurez-vous d'importer votre service de token
import authHeader from "../../service/auth/auth-header";

// Profile experience

const fetchContractTypes = async () => {
  return await api.get("api/v1/public/contract-type");
};

const fetchLevelTypes = async () => {
  return await api.get("api/v1/public/levels");
};

const fetchLanguageTypes = async () => {
  return await api.get("api/v1/public/language");
};

const fetchCandidateExperiences = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidate/experiences/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des expériences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateExperience = async (experienceData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidate/experiences/me`,
    experienceData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateExperience = async (experienceData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidate/experiences",
    experienceData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateExperience = async (experienceId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidate/experiences/${experienceId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateExperienceById = async (experienceId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidate/experiences/${experienceId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};
// Formation

const fetchCandidateEducations = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidate/training-candidate/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des expériences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateEducation = async (educationData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidate/training-candidate/me`,
    educationData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateEducation = async (educationData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidate/training-candidate",
    educationData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateEducation = async (educationId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidate/training-candidate/${educationId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateEducationById = async (educationId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidate/training-candidate/${educationId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

// Skills

const fetchCandidateSkills = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidate/skills/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des compétences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateSkill = async (skillData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(`/api/v1/candidate/skills/me`, skillData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateSkill = async (skillData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put("/api/v1/candidate/skills", skillData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateSkill = async (skillId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(`/api/v1/candidate/skills/${skillId}`, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateSkillById = async (skillId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(`/api/v1/candidate/skills/${skillId}`, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données de la réponse après suppression
};

// language-candidate

const fetchCandidatelanguages = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidate/language-candidate/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des langues :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateLanguage = async (languageData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidate/language-candidate/me`,
    languageData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateLanguage = async (languageData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidate/language-candidate",
    languageData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateLanguage = async (languageId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidate/language-candidate/${languageId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateLanguageById = async (languageId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidate/language-candidate/${languageId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

//Info Perso

const fetchCandidateInfoPerso = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidate/profile", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations personnelles :",
      error
    );
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const updateCandidateInfoPerso = async (infoPersoData) => {
  const accessToken = TokenService.getUser();
  const response = await api.put("/api/v1/candidate/profile", infoPersoData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet mis à jour
};

// Exporter les services
const ProfileService = {
  fetchLanguageTypes,
  fetchLevelTypes,
  fetchCandidateExperiences,
  addCandidateExperience,
  updateCandidateExperience,
  deleteCandidateExperience,
  fetchContractTypes,
  fetchCandidateExperienceById,
  fetchCandidateEducations,
  addCandidateEducation,
  updateCandidateEducation,
  deleteCandidateEducation,
  fetchCandidateEducationById,
  fetchCandidateSkills,
  addCandidateSkill,
  updateCandidateSkill,
  deleteCandidateSkill,
  fetchCandidateSkillById,
  fetchCandidatelanguages,
  addCandidateLanguage,
  updateCandidateLanguage,
  deleteCandidateLanguage,
  fetchCandidateLanguageById,
  fetchCandidateInfoPerso,
  updateCandidateInfoPerso,
};

export default ProfileService;
