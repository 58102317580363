import React from "react";
import { Container, Row } from "reactstrap";
import Section from "../../Blog/BlogAuther/Section";
import BeneficiaryContent from "../../Blog/BlogAuther/beneficiaryContent";
import TokenService from "../../../service/TokenService";
import Content from "../../Blog/BlogAuther/Content";

const BlogAuther = () => {
  document.title = "Ma carrière | RecruTech";
  
  // Récupérer l'utilisateur connecté
  const currentUser = TokenService.getUser();
  const userRole = currentUser?.authorities[0]; // Récupérer le rôle de l'utilisateur

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {/* Condition pour afficher le bon contenu selon le rôle */}
            {userRole === 'ROLE_BENEFICIARY_ACADEMY' ? (
              <BeneficiaryContent />
            ) : (
              <Content />
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogAuther;
