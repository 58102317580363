import React from "react";
import { Card } from "reactstrap";

//swiper css
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./BlogSwiper.css"; // Importer le fichier CSS

const BlogSwiper = ({ selectedDetails }) => {
  const coverPhoto =
    selectedDetails?.coverPhoto ||
    "https://res.cloudinary.com/dryskp7hv/image/upload/v1723497638/e737f542-b510-44d9-95c8-2970f03975b9.png";

  return (
    <React.Fragment>
      <Card className="card border-container">
        <img src={coverPhoto} alt="" className="img-fluid full-width-image" />
      </Card>
    </React.Fragment>
  );
};

export default BlogSwiper;
