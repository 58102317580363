import React, { useState, useRef } from 'react';
import { Card, CardBody, Col, Row, Progress, Input, Container, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Importez useHistory depuis react-router-dom

//Noui slider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";


const CompleteProfileContent = () => {
    const [selectedCards, setSelectedCards] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCv, setSelectedCv] = useState(null);
    const fileInputRef = useRef(null);
    const imgInputRef = useRef(null);
    const navigate = useNavigate(); // Utilisez history pour gérer la redirection

    const jobsProfile = [
        {
            id: 1,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257019/Eburnie%20hub/logo/devicon_php_e5r4vu.png",
            title: "Backend PHP",
        },
        {
            id: 2,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/v1697811024/Eburnie%20hub/logo/devicon_figma_zbegnr.png",
            title: "UX/UI Design",
        },
        {
            id: 3,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257140/Eburnie%20hub/logo/fa6-brands_node-js_fqx06m.png",
            title: "Node.js",
        },
        {
            id: 4,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257202/Eburnie%20hub/logo/ri_reactjs-line_vuw0fk.png",
            title: "React.js",
        },
        {
            id: 5,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257140/Eburnie%20hub/logo/fa6-brands_node-js_fqx06m.png",
            title: "Node.js",
        },
        {
            id: 6,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257306/Eburnie%20hub/logo/logos_angular-icon_geaiv1.png",
            title: "Frontend Angular",
        },
        {
            id: 7,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/v1697811024/Eburnie%20hub/logo/logos_flutter_lid5ol.png",
            title: "Flutter Mobile",
        },
        {
            id: 8,
            logo: "https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699257019/Eburnie%20hub/logo/devicon_php_e5r4vu.png",
            title: "Backend PHP",
        },
    ];

    const cardStyle = {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        transition: 'transform 0.2s',
        overflow: 'hidden',
        margin: 'auto',
    };

    const selectedCardStyle = {
        border: '2px solid #50d3bb', /* Couleur de la bordure pour les cartes sélectionnées */
        transform: 'scale(0.95)',
    };
    const handleCardClick = (id) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter(cardId => cardId !== id));
        } else {
            setSelectedCards([...selectedCards, id]);
        }
    };

    const handleNextStep = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    // Profile

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setSelectedImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageClick = () => {
        imgInputRef.current.click();
    };

    const imageStyle = {
        cursor: 'pointer', // Change le curseur en main au survol de l'image
    };

    // Cv 
    const handleCvChange = (event) => {
        const file = event.target.files[0];
        setSelectedCv(file);
    };

    const handleCvClick = () => {
        // Cela ouvrira le sélecteur de fichiers lorsque l'utilisateur cliquera sur l'élément visuel.
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFormSubmit = () => {
        navigate('/');
    }


    return (
        <React.Fragment>
            <br />
            <Progress className="mt-4" value={currentStep * 25}>
                {`${currentStep}/4`}
            </Progress>
            {currentStep === 1 && (
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="mb-1 mt-4 pb-3 me-lg-5">
                            <h3 className="title">Quel emploi désirez-vous ?</h3>
                        </div>
                    </Col>
                </Row>
            )}

            <Row className="mt-4">
                {currentStep === 1 && (

                    <form className="position-relative">
                        <Input className="form-control" type="search" placeholder="Commencez par chercher votre Emploi ici" />
                        <button className="bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-5" type="submit"><span className="mdi mdi-magnify text-muted"></span></button>
                    </form>
                )}
                {currentStep === 1 && (
                    jobsProfile.map((jobProfile, key) => (
                        <Col lg={3} md={6} key={key}>
                            <Card
                                className={`text-center mb-4 profile-card`}
                                style={{
                                    ...cardStyle,
                                    ...(selectedCards.includes(jobProfile.id) ? selectedCardStyle : {}),
                                }}
                            >
                                <div className="form-check mt-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input position-absolute top-0 end-0 m-2"
                                        checked={selectedCards.includes(jobProfile.id)}
                                        onChange={() => handleCardClick(jobProfile.id)}
                                    />
                                </div>
                                <CardBody className="px-4 py-5">
                                    <img src={jobProfile.logo} alt="" className="img-fluid rounded-3" />
                                    <div className="mt-4">
                                        <p className="text-muted mb-4">{jobProfile.title}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
            {currentStep === 2 && (
                <Container>

                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="mb-1 mt-4 pb-3 me-lg-5">
                                <h3 className="title">CVthèque</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <div className="mt-1">
                                <p className="title">Ajoutez une photo</p>
                            </div>
                            <div>
                                <div className="profile-picture" onClick={handleImageClick}>
                                    {selectedImage ? ( 
                                        <img src={selectedImage} alt=""
                                            style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover', ...imageStyle }} />
                                    ) : (
                                        <img src="https://res.cloudinary.com/dryskp7hv/image/upload/c_thumb,w_83/v1699269287/Eburnie%20hub/logo/Profil_photo_add_box_wdrbek.png"
                                            alt="" style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'none', ...imageStyle }} />
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={imgInputRef}
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-start mt-5 mb-5">
                        <Col lg={6} className="d-flex align-items-center justify-content-center">
                            <div>
                                <p className="title">Curriculum vitæ (CV)</p>
                                <div className="profile-picture" onClick={handleCvClick}>
                                    {selectedCv ? (
                                        <p className="selected-cv" >{selectedCv.name}</p>
                                    ) : (
                                        <img src="https://res.cloudinary.com/dryskp7hv/image/upload/c_scale,h_150,w_180/v1699542144/Eburnie%20hub/logo/CV_Downlod_Box_oeaajv.png"
                                            alt="Default Avatar" style={{ borderRadius: '10%', objectFit: 'none', ...imageStyle }} />
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept=".pdf, .doc, .docx"
                                        id="cvInput"
                                        ref={fileInputRef}
                                        onChange={handleCvChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex align-items-center justify-content-center">
                            <div>
                                <p className="title">Lettre de motivation</p>
                                <div className="profile-picture" onClick={handleCvClick}>
                                    {selectedCv ? (
                                        <p className="selected-cv" >{selectedCv.name}</p>
                                    ) : (
                                        <img src="https://res.cloudinary.com/dryskp7hv/image/upload/c_scale,h_150,w_180/v1699542144/Eburnie%20hub/logo/CV_Downlod_Box_oeaajv.png"
                                            alt="Default Avatar" style={{ borderRadius: '10%', objectFit: 'none', ...imageStyle }} />
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept=".pdf, .doc, .docx"
                                        id="cvInput"
                                        ref={fileInputRef}
                                        onChange={handleCvChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="card p-3 mb-1 mt-4 pb-3 me-lg-5">
                                <div className="area-range ">
                                    <Nouislider
                                        range={{ min: 0, max: 40 }}
                                        start={5}
                                        step={1}
                                        connect
                                        color="primary"
                                        tooltips={true}
                                    />
                                    <div className="form-label mt-3">Niveau d’expérience<span className="example-val mt-2" id="slider1-span" ></span></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center mb-5">
                        <Col lg={12}>
                            <div className="card p-3 mb-1 mt-4 pb-3 me-lg-5">
                                <div className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h5 className="title">Offre d’emploi</h5>
                                        <p className="text-start">Me notifier des nouvelles offres d’emplois</p>
                                    </div>
                                    <div className="form-check form-switch form-switch-sm text-start">
                                        <input style={{ width: '50px', height: '25px' }} className="form-check-input mt-4" type="checkbox" id="switch1" />
                                    </div>
                                </div>
                                <hr className="my-4" /> {/* Ligne médiane */}
                                <div className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h5 className="title">Candidature</h5>
                                        <p className="text-start">Me notifier quand ma candidature à une offre est accptée</p>
                                    </div>
                                    <div className="form-check form-switch form-switch-lg text-start">
                                        <input style={{ width: '50px', height: '25px' }} className="form-check-input mt-4" type="checkbox" id="switch1" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>

            )}
            {currentStep === 3 && (
                <Container>

                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="mb-1 mt-4 pb-3 me-lg-5">
                                <h3 className="title">Prise en charge de lieu de l’Utilisateur</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="mb-3">
                                <Label for="languageSelect" className="form-label">
                                    Langue*
                                </Label>
                                <div className="selection-widget mt-2 mt-lg-0">
                                    <select className="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="" disabled>Sélectionnez une langue</option>
                                        {/* <option value="fr">Français</option>
                                        <option value="en">Anglais</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label for="languageSelect" className="form-label">
                                    Pays*
                                </Label>
                                <div className="selection-widget mt-2 mt-lg-0">
                                    <select className="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="" disabled>Sélectionnez un pays</option>
                                        {/* <option value="ci">Côte d'ivoire</option>
                                        <option value="tg">Togo</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label for="regionControlInput" className="form-label">
                                    Région*
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="regionControlInput"
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="villeControlInput" className="form-label">
                                    Ville*
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="villeControlInput"
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="lieuControlInput" className="form-label">
                                    Lieu d’habitation*
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="lieuControlInput"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>

            )}
            {currentStep === 4 && (
                <Container>

                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="mb-1 mt-4 pb-3 me-lg-5">
                                <h3 className="title">Prise en charge de lieu de l’Emploi</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="mb-3">
                                <Label for="languageSelect" className="form-label">
                                    Région de l’emploi*
                                </Label>
                                <div className="selection-widget mt-2 mt-lg-0">
                                    <select className="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="" disabled>Sélectionnez une région</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label for="languageSelect" className="form-label">
                                    Ville de l’emploi*
                                </Label>
                                <div className="selection-widget mt-2 mt-lg-0">
                                    <select className="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="" disabled>Sélectionnez une Ville</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label for="phoneInput" className="form-label">
                                    Téléphone
                                </Label>
                                <div className="input-group">
                                <select className="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="+1">+225 (Côte d'ivoire)</option>
                                        <option value="+44">+228 (Togo)</option>
                                        <option value="+33">+33 (France)</option>
                                        {/* Ajoutez d'autres indicatifs de pays au besoin */}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phoneInput"
                                        placeholder="Numéro de téléphone"
                                    />
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            )}
            <div className="text-center">
                <Row className="align-items-start">
                    <Col lg={6}>
                        {currentStep > 1 && currentStep < 4 && (
                            <button className="btn btn-primary btn-hover rounded-pill" type="submit" onClick={handlePreviousStep}>
                                <i className="uil uil-angle-left me-1"></i> Retour
                            </button>
                        )}
                    </Col>
                    <Col lg={6}>
                        {currentStep < 4 && (
                            <button className="btn btn-primary btn-hover rounded-pill" type="submit" onClick={handleNextStep}>
                                Étape suivante <i className="uil uil-angle-right me-1"></i>
                            </button>
                        )}
                                                {currentStep === 4 && (
                            <button className="btn btn-primary btn-hover rounded-pill" type="submit" onClick={handleFormSubmit}>
                                Terminer 
                            </button>
                        )}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default CompleteProfileContent;
