import React from "react";
import { Col, Row } from "reactstrap";
import { Skeleton, Card } from "antd";

const JobCardItemSkeleton = () => {
  return (
    <Col md={12}>
      <Card className="job-box card mt-4 h-100 d-flex flex-column">
        <div className="p-4 flex-grow-1">
          <Row className="align-items-center mb-1">
            <Col md={9}>
              <div className="text-start">
                <Skeleton.Input active size="large" style={{ width: 200 }} />
              </div>
            </Col>
            <Col md={3}>
              <div className="text-end mb-4">
                <Skeleton.Avatar active size={40} shape="circle" />
              </div>
            </Col>
          </Row>
          <Row className=" text-align-justify mb-0">
            <Col md={7}>
              <div style={{ textAlign: "justify" }} className="text-start mb-0">
                <Skeleton.Button active size="small" shape="round" />
                <Skeleton.Button active size="small" shape="round" className="ms-2" />
              </div>
            </Col>
            <Col md={5}>
              <div className="text-end mb-0">
                <Skeleton.Input active size="small" style={{ width: 100 }} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-3 bg-light">
          <Row className="justify-content-between">
            <Col md={8}>
              <div className="d-flex mb-0">
                <Skeleton.Input active size="small" style={{ width: 150 }} />
              </div>
            </Col>
            <Col lg={4} md={3}>
              <div className="d-flex mb-0">
                <Skeleton.Input active size="small" style={{ width: 100 }} />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default JobCardItemSkeleton;
