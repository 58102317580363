import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
// css
import "./BlogDetails.css";

const Section = ({ selectedDetails = {} }) => {
  const title = selectedDetails.title ? selectedDetails.title.toUpperCase() : '';

  return (
    <React.Fragment>
      <section className="custom-background-section-training">
      <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">{title}</h3>
                <div className="page-next">
                  <nav className="d-inline-block" aria-label="breadcrumb text-center">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Accueil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Formation</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Détails
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
