import api from "../Api";
import TokenService from "../TokenService"; // Assurez-vous d'importer votre service de token
import authHeader from "../../service/auth/auth-header";

const fetchBeneficiaryAcademies = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/beneficiary-academies/academy/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des académies :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const fetchCandidteTrainings = async (page = 0, companyId = "") => {
  const url = `/api/v1/public/candidate/trainings?size=6&page=${page}${
    companyId ? `&companyId=${companyId}` : ""
  }`;
  return await api.get(url);
};

const fetchBeneficiaryTrainings = async () => {
  // Récupérer le token d'accès
  const accessToken = TokenService.getUser();
  const { data: response } = await api.get(
    "/api/v1/beneficiary-academies/trainings/me",
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response;
};

const fetchBeneficiaryProjects = async () => {
  // Récupérer le token d'accès
  const accessToken = TokenService.getUser();
  const { data: response } = await api.get(
    "/api/v1/beneficiary-academies/project-beneficiary-academy/me",
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response;
};

const fetchTrainingDetails = async (slug) => {
  const url = `/api/v1/public/candidate/training/${slug}`;
  return await api.get(url);
};

const fetchRecentTrainings = async () => {
  return await api.get("api/v1/public/candidate/training/recently");
};

const addProject = async (academyId) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/beneficiary-academies/project-beneficiary-academy/academy/${academyId.academyId}/me`,
    academyId.projectData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateProject = async (projectData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/beneficiary-academies/project-beneficiary-academy",
    projectData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteProject = async (projectId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/beneficiary-academies/project-beneficiary-academy/${projectId.projectId}/me`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const TrainingService = {
  fetchCandidteTrainings,
  fetchTrainingDetails,
  fetchRecentTrainings,
  fetchBeneficiaryTrainings,
  fetchBeneficiaryAcademies,
  fetchBeneficiaryProjects,
  addProject,
  updateProject,
  deleteProject,
};

export default TrainingService;
